<template>
  <el-table :data="tableData4" style="width: 100%">
    <el-table-column prop="type" label="记录类型" width="180"> </el-table-column>
    <el-table-column prop="fee" label="金额（万元）" width="180"> </el-table-column>
    <el-table-column prop="create_user_name" label="创建人" width="180"> </el-table-column>
    <el-table-column prop="create_time" label="创建时间" width="180"> </el-table-column>
  </el-table>
</template>

<script>
export default {
  data() {
    return {
      activeName: 'first',
      tableData1: [
        { name: '设计文档1.0', url: 'https://www.baidu.com/', create_user_name: '管理员', create_time: '2023-04-18 12:12'}
      ],
      tableData2: [
        { name: '评审文档1.0', url: 'https://www.baidu.com/', create_user_name: '管理员', create_time: '2023-04-18 12:12'}
      ],
      tableData3: [
        { name: '招标文档1.0', url: 'https://www.baidu.com/', create_user_name: '管理员', create_time: '2023-04-18 12:12'}
      ],
      tableData4: [
        { type: '付款', fee: 100, create_user_name: '管理员', create_time: '2023-04-18 12:12'},
        { type: '整合', fee: 20, create_user_name: '管理员', create_time: '2023-04-18 12:12'}
      ],
    }
  }
}
</script>